<template>
  <div class="home">
    <div class="section-2">
      <v-card color="white" width="100%" flat>
        <v-container class="py-8 py-md-15">
          <v-row>
            <!-- A loan that works for you -->
            <v-col cols="12">
              <h3 class="heading-h3 mb-md-2">
                Tailored Short Term Loans Online
              </h3>
            </v-col>

            <!-- carousel -->
            <v-col
              class="hidden-xs-only"
              v-for="(listItem, index) in listItems"
              :key="index"
              cols="12"
              sm="4"
              lg="4"
            >
              <div class="d-flex align-start">
                <!-- img -->
                <v-img
                  alt="pen"
                  class="pen mt-2"
                  contain
                  :src="listItem.imgSrc"
                  max-width="24"
                />

                <!-- Text -->
                <div class="ml-3 mt-1">
                  <h4 class="heading-h4 font-weight-bold">
                    {{ listItem.title }}
                  </h4>

                  <p class="b-body-1 mt-2">
                    {{ listItem.description }}
                  </p>
                </div>
              </div>
            </v-col>

            <!-- carousel -->
            <v-col class="hidden-sm-and-up" cols="12">
              <v-carousel
                :continuous="false"
                :show-arrows="false"
                height="150px"
                delimiter-icon=""
                hide-delimiter-background
                class="cssClass"
              >
                <v-carousel-item
                  v-for="(listItem, index) in listItems"
                  :key="index"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                >
                  <div class="d-flex align-start">
                    <!-- img -->
                    <v-img
                      alt="pen"
                      class="pen mt-2"
                      contain
                      :src="listItem.imgSrc"
                      max-width="24"
                    />

                    <!-- Text -->
                    <div class="ml-3">
                      <h4 class="heading-h4 font-weight-bold">
                        {{ listItem.title }}
                      </h4>

                      <p class="b-body-1">
                        {{ listItem.description }}
                      </p>
                    </div>
                  </div>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import img1 from "@/assets/img/public/pages/home/section-2/pen.svg";
import img2 from "@/assets/img/public/pages/home/section-2/globe.svg";
import img3 from "@/assets/img/public/pages/home/section-2/wallet.svg";

export default {
  name: "LimeLoansSection2",

  data() {
    return {
      listItems: [
        {
          imgSrc: img1,
          title: "Create Your Loan Profile",
          description:
            "Start your loan application by entering your personal information and mobile phone number.",
        },
        {
          imgSrc: img2,
          title: "Customize Your Loan Conditions",
          description: `After providing your latest 3 months' bank statements and salary details, you'll receive personalized loan terms that work for you.`,
        },
        {
          imgSrc: img3,
          title: "Instant Loan Funding",
          description: `Select your loan amount and term, sign the contract, and the money 
          will be instantly transferred to your bank account. Experience the ease of cash loans payday loans with Lime24.`,
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .b-body-1 {
    //styleName: 14 S/normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
}
</style>
