<template>
  <div class="home">
    <div class="section-3">
      <v-card color="background" width="100%" flat>
        <v-container class="py-8 py-md-15">
          <v-row class="pb-md-5">
            <!-- Customer’s opinion -->
            <v-col class="mb-md-9" cols="12">
              <h3 class="heading-h3">Customer’s opinion</h3>
            </v-col>

            <!-- Desktop -->
            <!-- customers -->
            <v-col
              class="hidden-xs-only"
              v-for="(customer, index) in customers"
              :key="index"
              cols="12"
              sm="4"
              lg="4"
            >
              <div class="home-card">
                <v-card
                  class="transparent padding d-flex flex-column justify-space-between"
                  width="378.67"
                  height="244"
                  elevation="0"
                  flat
                >
                  <div class="div">
                    <div class="d-flex align-center">
                      <!-- img -->
                      <v-img
                        alt="pen"
                        class="pen"
                        contain
                        :src="customer.imgSrc"
                        max-width="56"
                      />

                      <h4 class="heading-h4 font-weight-bold ml-5">
                        {{ customer.title }}
                      </h4>
                    </div>

                    <!-- p -->
                    <p class="b-body-1 mt-3">
                      {{ customer.description }}
                    </p>
                  </div>

                  <v-btn
                    @click="setDialog(customer.url)"
                    class="b-subtitle-1 primary--text ml-16 mt-n1"
                    icon
                  >
                    <div class="d-flex align-center">
                      <v-img
                        alt="play"
                        class="play"
                        contain
                        src="@/assets/img/public/pages/home/section-3/play.svg"
                        max-width="24"
                      />

                      <h6
                        class="b-subtitle-1 primary--text text-capitalize ml-1"
                      >
                        Watch the video
                      </h6>
                    </div>
                  </v-btn>
                </v-card>
              </div>
            </v-col>

            <!-- customer -->
            <v-col
              class="hidden-xs-only"
              cols="12"
              sm="4"
              lg="4"
              offset-sm="4"
              offset-lg="4"
            >
              <div class="home-card">
                <v-card
                  class="transparent padding d-flex flex-column justify-space-between"
                  width="378.67"
                  height="244"
                  elevation="0"
                  flat
                >
                  <div class="div">
                    <div class="d-flex align-center">
                      <!-- img -->
                      <v-img
                        alt="pen"
                        class="pen"
                        contain
                        :src="customer.imgSrc"
                        max-width="56"
                      />

                      <h4 class="heading-h4 font-weight-bold ml-5">
                        {{ customer.title }}
                      </h4>
                    </div>

                    <!-- p -->
                    <p class="b-body-1 mt-3">
                      {{ customer.description }}
                    </p>
                  </div>

                  <v-btn
                    @click="setDialog(customer.url)"
                    class="b-subtitle-1 primary--text ml-16 mt-n1"
                    icon
                  >
                    <div class="d-flex align-center">
                      <v-img
                        alt="play"
                        class="play"
                        contain
                        src="@/assets/img/public/pages/home/section-3/play.svg"
                        max-width="24"
                      />

                      <h6
                        class="b-subtitle-1 primary--text text-capitalize ml-1"
                      >
                        Watch the video
                      </h6>
                    </div>
                  </v-btn>
                </v-card>
              </div>
            </v-col>

            <!-- Mobile -->
            <v-col class="hidden-sm-and-up mt-n5" cols="12">
              <v-carousel
                :continuous="false"
                :show-arrows="false"
                height="240px"
                delimiter-icon=""
                hide-delimiter-background
                class="cssClass"
              >
                <v-carousel-item
                  v-for="(customer, index) in mobileCustomers"
                  :key="index"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                >
                  <v-card
                    class="background pa-4 d-flex flex-column justify-space-between"
                    height="210"
                    tile
                    flat
                  >
                    <div class="div">
                      <div class="d-flex align-center">
                        <!-- img -->
                        <v-img
                          alt="pen"
                          class="pen mt-2"
                          contain
                          :src="customer.imgSrc"
                          max-width="56"
                        />

                        <h4 class="heading-h4 font-weight-bold ml-5">
                          {{ customer.title }}
                        </h4>
                      </div>

                      <!-- p -->
                      <p class="b-body-1 mt-3">
                        {{ customer.description }}
                      </p>
                    </div>

                    <!-- Mobile -->
                    <div
                      @click="setDialogMobile(customer.url)"
                      class="hidden-sm-and-up b-subtitle-2 primary--text pointer"
                      icon
                    >
                      <div class="d-flex align-center">
                        <v-img
                          alt="play"
                          class="play"
                          contain
                          src="@/assets/img/public/pages/home/section-3/play.svg"
                          max-width="24"
                        />

                        <h6
                          class="b-subtitle-2 primary--text text-capitalize ml-1"
                        >
                          Watch the video
                        </h6>
                      </div>
                    </div>
                  </v-card>
                </v-carousel-item>
              </v-carousel>
            </v-col>

            <!-- dialog -->
            <!-- Desktop -->
            <v-dialog
              class="hidden-xs-only"
              v-model="dialog"
              width="900"
              overlay-opacity="0.75"
            >
              <v-card color="transparent" width="100%" flat tile>
                <v-responsive :aspect-ratio="16 / 9">
                  <!--  -->
                  <iframe
                    width="100%"
                    height="100%"
                    :src="url"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </v-responsive>
              </v-card>
            </v-dialog>

            <!-- close btn -->
            <v-btn v-if="dialog" @click="close()" class="z-index" icon>
              <v-img
                alt="times"
                class="times"
                contain
                src="@/assets/img/public/pages/home/section-3/times.svg"
                max-width="24"
              />
            </v-btn>

            <!-- Mobile -->
            <v-dialog
              class="hidden-sm-and-up"
              v-model="dialogMobile"
              width="100%"
              overlay-opacity="0.75"
            >
              <v-card
                class="custom-dialog"
                color="transparent"
                width="100%"
                flat
                tile
              >
                <v-responsive class="pa-0 ma-0" :aspect-ratio="16 / 9">
                  <!--  -->
                  <iframe
                    width="100%"
                    height="100%"
                    :src="url"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </v-responsive>
              </v-card>
            </v-dialog>

            <!-- close btn -->
            <v-btn
              v-if="dialogMobile"
              @click="closeMobile()"
              class="z-index"
              icon
            >
              <v-img
                alt="times"
                class="times-mobile"
                contain
                src="@/assets/img/public/pages/home/section-3/times.svg"
                max-width="24"
              />
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import thaboImg from "@/assets/img/public/pages/home/section-3/thabo.png";
import estherImg from "@/assets/img/public/pages/home/section-3/esther.png";
import johanImg from "@/assets/img/public/pages/home/section-3/johan.png";
import nonhalanhlaImg from "@/assets/img/public/pages/home/section-3/nonhalanhla.png";

export default {
  name: "LimeLoansSection3",

  data() {
    return {
      dialog: false,
      dialogMobile: false,
      url: "https://www.youtube.com/embed/F_RVNKlvZzg",

      // customers
      customers: [
        //   Thabo
        {
          imgSrc: thaboImg,
          title: "Thabo",
          description: `Thabo can always rely on a Lime24 loan when he runs out of money before payday.`,
          url: "https://www.youtube.com/embed/F_RVNKlvZzg",
        },
        // Esther
        {
          imgSrc: estherImg,
          title: "Esther",
          description: `Esther is a single mother. Lime24 loans help her cover various family expenses. `,
          url: "https://www.youtube.com/embed/rgYOfRtZ4BM",
        },
        // Nonhalanhla
        {
          imgSrc: nonhalanhlaImg,
          title: "Nonhalanhla",
          description: `Nonhalanhla is financially supported by Lime24 loans whenever she needs.`,

          url: "https://www.youtube.com/embed/OpYTzbZW3mU",
        },
      ],

      // customer
      customer:
        // Johan
        {
          imgSrc: johanImg,
          title: "Johan",
          description: `Johan needs money to pay his rent. He can always count on Lime24 to get his loans fast.`,

          url: "https://www.youtube.com/embed/MJ_iU3HDa70",
        },

      // mobileCustomers
      mobileCustomers: [
        //   Thabo
        {
          imgSrc: thaboImg,
          title: "Thabo",
          description: `Thabo can always rely on a Lime24 loan when he runs out of money before payday.`,
          url: "https://www.youtube.com/embed/F_RVNKlvZzg",
        },
        // Esther
        {
          imgSrc: estherImg,
          title: "Esther",
          description: `Esther is a single mother. Lime24 loans help her cover various family expenses. `,
          url: "https://www.youtube.com/embed/rgYOfRtZ4BM",
        },
        // Nonhalanhla
        {
          imgSrc: nonhalanhlaImg,
          title: "Nonhalanhla",
          description: `Nonhalanhla is financially supported by Lime24 loans whenever she needs.`,

          url: "https://www.youtube.com/embed/OpYTzbZW3mU",
        },
        // Johan
        {
          imgSrc: johanImg,
          title: "Johan",
          description: `Johan needs money to pay his rent. He can always count on Lime24 to get his loans fast.`,

          url: "https://www.youtube.com/embed/MJ_iU3HDa70",
        },
      ],
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogMobile(val) {
      val || this.closeMobile();
    },
  },

  mounted() {},

  methods: {
    setDialog(url) {
      this.url = url;

      this.dialog = true;
    },

    setDialogMobile(url) {
      this.url = url;

      this.dialogMobile = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.url = "";
      });
    },

    closeMobile() {
      this.dialogMobile = false;
      this.$nextTick(() => {
        this.url = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.padding {
  padding: 30px;
}

.custom-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}

.z-index {
  z-index: 1000;
}
.times,
.times-mobile {
  position: fixed;
}

.times {
  top: 36px;
  right: 36px;
}

.times-mobile {
  top: 32px;
  right: 32px;
}

@media only screen and (max-width: 768px) {
  .padding {
    padding: 12px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .padding {
    padding: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .b-body-1 {
    //styleName: 14 S/normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
}
</style>
