<template>
  <div class="home">
    <div class="section-1">
      <v-container class="py-8 py-md-15">
        <v-row>
          <!-- We’ve got you covered -->
          <v-col cols="12" class="ma-0">
            <h3 class="heading-h3 mb-md-9">Reliable Payday Loan Services</h3>
          </v-col>

          <!-- Fast application processing -->
          <v-col class="hidden-xs-only" cols="12" sm="6" lg="4">
            <div class="home-card">
              <v-card
                class="transparent padding"
                width="378.67"
                height="680"
                elevation="0"
                flat
              >
                <v-img
                  alt="time"
                  class="time ma-1"
                  contain
                  src="@/assets/img/public/pages/home/section-1/time.svg"
                  max-width="64"
                />

                <h4 class="heading-h5 font-weight-bold mt-3 mt-md-7 mr-n2">
                  Fast Loan Applications Online
                </h4>

                <p class="b-body-1 mt-3">
                  Apply for swift payday loans online; approval leads to fast
                  funding. Our efficient process is tailored for convenience,
                  ensuring a hassle-free experience. Secure and reliable, our
                  platform offers competitive rates and flexible terms. Get the
                  financial support you need with minimal paperwork and quick
                  turnaround. Trust us for your short-term borrowing needs.
                </p>
              </v-card>
            </div>
          </v-col>

          <!-- Transparency -->
          <v-col class="hidden-xs-only" cols="12" sm="6" lg="4">
            <div class="home-card">
              <v-card
                class="transparent padding"
                width="378.67"
                height="680"
                elevation="0"
                flat
              >
                <v-img
                  alt="money"
                  class="money ma-1"
                  contain
                  src="@/assets/img/public/pages/home/section-1/money.svg"
                  max-width="64"
                />

                <h4 class="heading-h5 font-weight-bold mt-3 mt-md-7">
                  Transparent Loan Terms
                </h4>

                <p class="b-body-1 mt-3">
                  With Lime24, experience utmost transparency in payday loan
                  conditions—no hidden fees, all costs vividly outlined. Our
                  straightforward approach ensures you're fully informed, making
                  your borrowing journey clear and predictable. Enjoy the
                  integrity of transparent lending; every term, fee, and
                  condition is clearly presented in your profile, empowering you
                  to make informed financial decisions with confidence. Trust in
                  our commitment to honesty and clarity, offering you a seamless
                  and trustworthy lending experience.
                </p>
              </v-card>
            </div>
          </v-col>

          <!-- Security -->
          <v-col class="hidden-xs-only" cols="12" sm="6" lg="4">
            <div class="home-card">
              <v-card
                class="transparent padding"
                width="378.67"
                height="680"
                elevation="0"
                flat
              >
                <v-img
                  alt="lock"
                  class="lock ma-1"
                  contain
                  src="@/assets/img/public/pages/home/section-1/lock.svg"
                  max-width="64"
                />

                <h4 class="heading-h5 font-weight-bold mt-3 mt-md-7">
                  Secure Online Loans
                </h4>

                <p class="b-body-1 mt-3">
                  Your data security is our top priority and we endeavour to
                  provide unparalleled protection. We adhere to stringent global
                  Visa and Mastercard standards, providing a safe platform for
                  your personal and financial information. Our compliance with
                  the National Credit Regulator and strict adherence to South
                  African laws reiterate our commitment to maintaining the
                  highest level of data integrity and privacy. You can trust in
                  our robust security measures, designed to safeguard your
                  information against unauthorized access and cyber threats.
                  Experience peace of mind knowing that your data is handled
                  with the utmost care and respect, backed by state-of-the-art
                  technology and rigorous legal compliance. With Lime24, your
                  information is not just protected; it's fortified.
                </p>
              </v-card>
            </div>
          </v-col>

          <!-- mobile -->
          <v-col class="hidden-sm-and-up" cols="12">
            <!-- Fast application processing -->
            <div class="d-flex align-start">
              <v-img alt="clock" class="clock m-right" contain width="20" />

              <div>
                <h3 class="mobile-h3">Fast Loan Applications Online</h3>
                <p class="light-text paragraph-spacing">
                  <span v-if="!showFullText1"
                    >{{ fastLoanText.substring(0, 100) }}...</span
                  >
                  <span v-else>{{ fastLoanText }}</span>
                  <span
                    @click="toggleText1"
                    class="see-more"
                    style="
                      cursor: pointer;
                      text-decoration: underline;
                      color: grey;
                    "
                  >
                    {{ showFullText1 ? "" : "Read more" }}
                  </span>
                </p>
              </div>
            </div>

            <!-- Transparency -->
            <div class="d-flex align-start">
              <v-img alt="clock" class="clock m-right" contain width="20" />

              <div>
                <h3 class="mobile-h3">Transparents Loan Terms</h3>
                <p class="light-text paragraph-spacing">
                  <span v-if="!showFullText2"
                    >{{ transparentLoanText.substring(0, 100) }}...</span
                  >
                  <span v-else>{{ transparentLoanText }}</span>
                  <span
                    @click="toggleText2"
                    class="see-more"
                    style="
                      cursor: pointer;
                      text-decoration: underline;
                      color: grey;
                    "
                  >
                    {{ showFullText2 ? "" : "Read more" }}
                  </span>
                </p>
              </div>
            </div>

            <!-- Security -->
            <div class="d-flex align-start">
              <v-img alt="clock" class="clock m-right" contain width="20" />

              <div>
                <h3 class="mobile-h3">Secure Online Loans</h3>
                <p class="light-text paragraph-spacing">
                  <span v-if="!showFullText3"
                    >{{ secureLoanText.substring(0, 100) }}...</span
                  >
                  <span v-else>{{ secureLoanText }}</span>
                  <span
                    @click="toggleText3"
                    class="see-more"
                    style="
                      cursor: pointer;
                      text-decoration: underline;
                      color: grey;
                    "
                  >
                    {{ showFullText3 ? "" : "Read more" }}
                  </span>
                </p>
              </div>
            </div>
          </v-col>

          <!-- Get Money -->
          <v-col class="d-flex justify-center mt-sm-10 mb-5" cols="12">
            <v-btn
              :to="{ name: 'RegistrationPage' }"
              class="b-button secondary text-capitalize hidden-xs-only"
              width="290"
              height="48"
            >
              Get Money
            </v-btn>

            <v-btn
              :to="{ name: 'RegistrationPage' }"
              class="b-button secondary text-capitalize hidden-sm-and-up my-n6"
              width="100%"
              height="48"
            >
              Get Money
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "LimeLoansSection1",

  data() {
    return {
      showFullText1: false,
      showFullText2: false,
      showFullText3: false,
      fastLoanText:
        "Apply for swift payday loans online; approval leads to fast funding. Our efficient process is tailored for convenience, ensuring a hassle-free experience. Secure and reliable, our platform offers competitive rates and flexible terms. Get the financial support you need with minimal paperwork and quick turnaround. Trust us for your short-term borrowing needs.",
      transparentLoanText:
        "With Lime24, experience utmost transparency in payday loan conditions—no hidden fees, all costs vividly outlined. Our straightforward approach ensures you're fully informed, making your borrowing journey clear and predictable. Enjoy the integrity of transparent lending; every term, fee, and condition is clearly presented in your profile, empowering you to make informed financial decisions with confidence. Trust in our commitment to honesty and clarity, offering you a seamless and trustworthy lending experience.",
      secureLoanText:
        "Your data security is our top priority and we endeavour to provide unparalleled protection. We adhere to stringent global Visa and Mastercard standards, providing a safe platform for your personal and financial information. Our compliance with the National Credit Regulator and strict adherence to South African laws reiterate our commitment to maintaining the highest level of data integrity and privacy. You can trust in our robust security measures, designed to safeguard your information against unauthorized access and cyber threats. Experience peace of mind knowing that your data is handled with the utmost care and respect, backed by state-of-the-art technology and rigorous legal compliance. With Lime24, your information is not just protected; it's fortified.",
    };
  },

  methods: {
    toggleText1() {
      this.showFullText1 = !this.showFullText1;
    },
    toggleText2() {
      this.showFullText2 = !this.showFullText2;
    },
    toggleText3() {
      this.showFullText3 = !this.showFullText3;
    },
  },
};
</script>

<style lang="scss" scoped>
.padding {
  padding: 30px;

  @media only screen and (max-width: 768px) {
    .padding {
      padding: 12px;
    }
  }
}

.mobile-h3 {
  //styleName: Header mobile/H3;
  font-family: "Raleway";
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
}

.m-right {
  margin-right: 10px;
}
.light-text {
  color: grey; /* You can adjust this to a lighter color like #D3D3D3 if needed */
}

.paragraph-spacing {
  margin-top: 10px; /* Adjust this value for the desired spacing */
}

@media only screen and (max-width: 600px) {
  .b-body-1 {
    //styleName: 14 S/normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
}
</style>
