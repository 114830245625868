<template>
  <div class="home-page">
    <HeroSection />
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import HeroSection from "@/components/public/pages/home/HeroSection";
import Section1 from "@/components/public/pages/home/Section1";
import Section2 from "@/components/public/pages/home/Section2";
import Section3 from "@/components/public/pages/home/Section3";
import Section4 from "@/components/public/pages/home/Section4";

export default {
  name: "LimeLoansHomepage",

  components: { HeroSection, Section1, Section2, Section3, Section4 },

  metaInfo: {
    title: "Lime Loans -  Quick and Secure Payday Loans Online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Need fast, secure payday loans? Lime24 offers online loan applications and instant funding. Apply now for competitive rates and personalised lending solutions.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "facebook-domain-verification",
        content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION,
      },
    ],
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      marketing: "marketing",
    }),
  },

  created() {
    let parameters = this.$route.query;

    // checkForMarketingQuery
    if (this.checkForMarketingQuery(parameters)) {
      this.resetMarketingState();

      this.$cookies.remove("traffic_source_data");

      // console.log("parameters @@@", parameters);

      this.setForMarketingQuery(parameters);

      // marketingSetSessionExpires
      this.marketingSetSessionExpires();
    }

    this.$helpers.resetErrorHandlerState();
  },

  mounted() {},

  methods: {
    ...mapMutations({
      // marketing
      setLeadId: "marketing/setLeadId",
      setUTMSource: "marketing/setUTMSource",
      setUTMMedium: "marketing/setUTMMedium",
      setUTMCampaign: "marketing/setUTMCampaign",
      setUTMContent: "marketing/setUTMContent",
      setUTMTerm: "marketing/setUTMTerm",
      setUTMWebmasterId: "marketing/setUTMWebmasterId",
      setClickId: "marketing/setClickId",
      setGClid: "marketing/setGClid",
      setMindboxClickId: "marketing/setMindboxClickId",

      marketingSetSessionExpires: "marketing/setSessionExpires",

      resetMarketingState: "marketing/resetState",
    }),

    // checkForMarketingQuery
    checkForMarketingQuery(parameters) {
      if (
        "lead_id" in parameters ||
        "utm_source" in parameters ||
        "utm_medium" in parameters ||
        "utm_campaign" in parameters ||
        "utm_content" in parameters ||
        "utm_term" in parameters ||
        "webmaster_id" in parameters ||
        "clickid" in parameters ||
        "gclid" in parameters ||
        "mindbox_click_id" in parameters
      ) {
        return true;
      }

      return false;
    },

    // setForMarketingQuery
    setForMarketingQuery(parameters) {
      // cookie
      var cookie = "";

      // lead_id
      if ("lead_id" in parameters) {
        this.setLeadId(parameters.lead_id);

        cookie += "&lead_id=" + parameters.lead_id;

        this.$router.push({ name: "PasswordRecoveryPage" });

      }

      // utm_source
      if ("utm_source" in parameters) {
        this.setUTMSource(parameters.utm_source);

        cookie += "&utm_source=" + parameters.utm_source;
      }

      // utm_medium
      if ("utm_medium" in parameters) {
        this.setUTMMedium(parameters.utm_medium);

        cookie += "&utm_medium=" + parameters.utm_medium;
      }

      // utm_campaign
      if ("utm_campaign" in parameters) {
        this.setUTMCampaign(parameters.utm_campaign);

        cookie += "&utm_campaign=" + parameters.utm_campaign;
      }

      // utm_content
      if ("utm_content" in parameters) {
        this.setUTMContent(parameters.utm_content);

        cookie += "&utm_content=" + parameters.utm_content;
      }

      // utm_term
      if ("utm_term" in parameters) {
        this.setUTMTerm(parameters.utm_term);

        cookie += "&utm_term=" + parameters.utm_term;
      }

      // webmaster_id
      if ("webmaster_id" in parameters) {
        this.setUTMWebmasterId(parameters.webmaster_id);

        cookie += "&webmaster_id=" + parameters.webmaster_id;
      }

      // clickid
      if ("clickid" in parameters) {
        this.setClickId(parameters.clickid);

        cookie += "&clickid=" + parameters.clickid;
      }

      // gclid
      if ("gclid" in parameters) {
        this.setGClid(parameters.gclid);

        cookie += "&gclid=" + parameters.gclid;
      }

      // mindbox_click_id
      if ("mindbox_click_id" in parameters) {
        this.setMindboxClickId(parameters.mindbox_click_id);

        cookie += "&mindbox_click_id=" + parameters.mindbox_click_id;
      }

      cookie = cookie.replace(cookie.charAt(0), "");

      // console.log("cookie", cookie);

      this.$cookies.set("traffic_source_data", cookie);

      // console.log(
      //   "traffic_source_data",
      //   this.$cookies.get("traffic_source_data")
      // );

      // this.$cookies.remove("traffic_source_data");
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
