<template>
  <div class="home">
    <div class="section-3">
      <v-card color="white" width="100%" flat>
        <v-container class="py-8 py-md-15">
          <v-row>
            <!-- Our partners -->
            <v-col cols="12">
              <h3 class="heading-h3">Our partners</h3>
            </v-col>

            <div class="hidden-xs-only">
              <v-card class="d-flex flex-wrap pa-4" flat tile>
                <!--  -->
                <div v-for="(listItem, index) in listItems" :key="index">
                  <v-img
                    :alt="listItem.title"
                    :class="listItem.class"
                    contain
                    :src="listItem.imgSrc"
                    :max-width="listItem.maxWidth"
                  />
                </div>
              </v-card>
            </div>

            <!-- carousel -->
            <v-col class="hidden-sm-and-up" cols="12">
              <v-carousel
                :continuous="false"
                :show-arrows="false"
                height="120px"
                delimiter-icon=""
                hide-delimiter-background
                class="cssClass"
              >
                <v-carousel-item
                  v-for="(listItem, index) in listItems"
                  :key="index"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                >
                  <div class="d-flex justify-center align-center">
                    <v-img
                      :alt="listItem.title"
                      :class="listItem.title"
                      contain
                      :src="listItem.imgSrc"
                      :max-width="listItem.maxWidth"
                    />
                  </div>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import kaspersky from "@/assets/img/public/pages/home/section-4/kaspersky.svg";
import experian from "@/assets/img/public/pages/home/section-4/experian.svg";
import truid from "@/assets/img/public/pages/home/section-4/truid.svg";
import sacrra from "@/assets/img/public/pages/home/section-4/sacrra.svg";
import pasa from "@/assets/img/public/pages/home/section-4/pasa.svg";
import debicheck from "@/assets/img/public/pages/home/section-4/debicheck.svg";
import ncr from "@/assets/img/public/pages/home/section-4/ncr.svg";
import intecon from "@/assets/img/public/pages/home/section-4/intecon.svg";
import runaloan from "@/assets/img/public/pages/home/section-4/runaloan.png";
import softidoc from "@/assets/img/public/pages/home/section-4/softidoc.png";

export default {
  name: "LimeLoansSection4",

  data() {
    return {
      // listItems
      listItems: [
        // kaspersky
        {
          imgSrc: kaspersky,
          title: "kaspersky",
          class: "kaspersky py-3 mr-md-6 mr-lg-12",
          maxWidth: 169.32,
        },
        // experian
        {
          imgSrc: experian,
          title: "experian",
          class: "experian py-3 mx-sm-5 mx-md-6 mx-lg-11",
          maxWidth: 168,
        },
        // truid
        {
          imgSrc: truid,
          title: "truid",
          class: "truid py-3 mx-sm-5 mx-md-6 ml-lg-12 mr-lg-11",
          maxWidth: 129.71,
        },
        // sacrra
        {
          imgSrc: sacrra,
          title: "sacrra",
          class: "sacrra py-3 mx-md-6 ml-lg-11 mr-lg-11",
          maxWidth: 229.28,
        },
        // pasa
        {
          imgSrc: pasa,
          title: "pasa",
          class: "pasa py-3 mx-sm-5 mr-lg-0 ml-lg-11",
          maxWidth: 69.23,
        },
        // debicheck
        {
          imgSrc: debicheck,
          title: "debicheck",
          class: "debicheck py-3 mx-sm-5 mx-md-6 ml-lg-0 mr-lg-11",
          maxWidth: 194.23,
        },
        // ncr
        {
          imgSrc: ncr,
          title: "ncr",
          class: "ncr py-3 mx-md-6 mx-lg-11",
          maxWidth: 97.5,
        },
        // intecon
        {
          imgSrc: intecon,
          title: "intecon",
          class: "intecon py-3 mx-sm-5 mx-md-6 ml-lg-0 mr-lg-11",
          maxWidth: 119.69,
        },
        // runaloan
        {
          imgSrc: runaloan,
          title: "runaloan",
          class: "runaloan py-3 mx-sm-5 mr-md-6 ml-lg-0 mr-lg-5",
          maxWidth: 229.69,
        },
        // softidoc
        {
          imgSrc: softidoc,
          title: "softidoc",
          class: "softidoc py-3 ml-lg-8",
          maxWidth: 150.69,
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .b-body-1 {
    //styleName: 14 S/normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
}
</style>
